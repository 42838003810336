<template>
  <v-dialog
    v-model="show"
    max-width="650"
  >
    <template v-slot:activator="{ on }">
      <slot
        v-bind="{
          on: on,
          loading: loading
        }"
      />
    </template>
    <template>
      <v-card>
        <v-card-title>{{ $t('dialogs.crPDFsGenerator.title') }}</v-card-title>
        <v-card-text>
          <v-select
            :label="$t('dialogs.crPDFsGenerator.catalog.label')"
            v-model="catalog"
            :error="$v.catalog.$error"
            :error-messages="!$v.catalog.required ? $t('validate.required', { field: $t('dialogs.crPDFsGenerator.catalog.label') }) : null"
            :items="[
              { value: 'create', text: $t('dialogs.crPDFsGenerator.catalog.options.create') },
              { value: 'change', text: $t('dialogs.crPDFsGenerator.catalog.options.change') },
              { value: 'updateInfo', text: $t('dialogs.crPDFsGenerator.catalog.options.updateInfo') }
            ]"
            clearable
          />
          <div v-if="catalog === 'create'">
            <v-checkbox
              v-model="forms"
              disabled
              hide-details
              multiple
              value="NNC1"
              :label="`NNC1 ${$t('dialogs.crPDFsGenerator.forms.options.NNC1')}`"
            />
            <v-checkbox
              v-model="forms"
              disabled
              hide-details
              multiple
              value="NNC1G"
              :label="`NNC1G ${$t('dialogs.crPDFsGenerator.forms.options.NNC1G')}`"
            />
            <v-checkbox
              v-model="forms"
              disabled
              hide-details
              multiple
              value="NN1"
              :label="`NN1 ${$t('dialogs.crPDFsGenerator.forms.options.NN1')}`"
            />
            <v-checkbox
              v-model="forms"
              disabled
              hide-details
              multiple
              value="IRBR1"
              :label="`IRBR1 ${$t('dialogs.crPDFsGenerator.forms.options.IRBR1')}`"
            />
            <v-checkbox
              v-model="forms"
              disabled
              hide-details
              multiple
              value="IRBR2"
              :label="`IRBR2 ${$t('dialogs.crPDFsGenerator.forms.options.IRBR2')}`"
            />
          </div>
          <div v-if="catalog === 'change'">
            <!-- {{ company.company_type }} -->
            <v-checkbox
              v-model="forms"
              :error="$v.forms.$error"
              :disabled="company.company_type === 6"
              hide-details
              multiple
              value="ND2A"
              :label="`ND2A ${$t('dialogs.crPDFsGenerator.forms.options.ND2A')}`"
            />
            <v-checkbox
              v-model="forms"
              :error="$v.forms.$error"
              :disabled="company.company_type === 6"
              hide-details
              multiple
              value="ND5"
              :label="`ND5 ${$t('dialogs.crPDFsGenerator.forms.options.ND5')}`"
            />
            <v-checkbox
              v-model="forms"
              :error="$v.forms.$error"
              :disabled="company.company_type !== 6"
              hide-details
              multiple
              value="NN6"
              :label="`NN6 ${$t('dialogs.crPDFsGenerator.forms.options.NN6')}`"
            />
            <v-checkbox
              v-model="forms"
              :error="$v.forms.$error"
              :disabled="company.company_type !== 6"
              hide-details
              multiple
              value="NN8"
              :label="`NN8 ${$t('dialogs.crPDFsGenerator.forms.options.NN8')}`"
            />
          </div>
          <div v-if="catalog === 'updateInfo'">
            <v-checkbox
              v-model="forms"
              :error="$v.forms.$error"
              :disabled="company.company_type === 6"
              hide-details
              multiple
              value="ND2B"
              :label="`ND2B ${$t('dialogs.crPDFsGenerator.forms.options.ND2B')}`"
            />
            <v-checkbox
              v-model="forms"
              :error="$v.forms.$error"
              :disabled="company.company_type === 6"
              hide-details
              multiple
              value="ND7"
              :label="`ND7 ${$t('dialogs.crPDFsGenerator.forms.options.ND7')}`"
            />
            <v-checkbox
              v-model="forms"
              :error="$v.forms.$error"
              :disabled="company.company_type !== 6"
              hide-details
              multiple
              value="NN7"
              :label="`NN7 ${$t('dialogs.crPDFsGenerator.forms.options.NN7')}`"
            />
            <v-checkbox
              v-model="forms"
              :error="$v.forms.$error"
              :disabled="company.company_type !== 6"
              hide-details
              multiple
              value="NN8C"
              :label="`NN8C ${$t('dialogs.crPDFsGenerator.forms.options.NN8C')}`"
            />
          </div>
          <div
            v-if="catalog === 'updateInfo' || catalog === 'change'"
            class="error--text"
          >
            {{ !$v.forms.required ? $t('validate.required', { field: $t('dialogs.crPDFsGenerator.forms.label') }) : null }}
          </div>
          <br>
          <v-list
            subheader
            v-if="(forms.includes('ND5') || forms.includes('ND2A') || forms.includes('NN6')) ||
              (forms.includes('ND7') || forms.includes('ND2B') || forms.includes('NN7'))"
          >
            <v-subheader>
              {{ [
                (forms.includes('ND2A') || forms.includes('NN6') || forms.includes('ND2B') || forms.includes('NN7')) ? $t('dialogs.crPDFsGenerator.director.label') : null,
                (forms.includes('ND5') || forms.includes('ND7')) ? $t('dialogs.crPDFsGenerator.reserveDirector.label') : null
              ].filter(Boolean).join(' / ') }}
            </v-subheader>

            <template v-for="(director, key) in availableDirectors">
              <v-list-item :key="`director_${key}`">
                <v-list-item-avatar>
                  <v-icon
                    dark
                    class="grey lighten-1"
                  >
                    {{ director.person ? 'mdi-account-outline' : 'mdi-domain' }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ [(director.person || director.company).names[0].sur, (director.person || director.company).names[0].other].filter(Boolean).join(' ') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon small>
                      mdi-briefcase
                    </v-icon>
                    {{
                      director.director_type === 1
                        ? $t('dialogs.crPDFsGenerator.director.label')
                        : (
                          director.director_type === 2
                            ? $t('dialogs.crPDFsGenerator.alternateDirector.label')
                            : (
                              director.director_type === 3
                                ? $t('dialogs.crPDFsGenerator.reserveDirector.label')
                                : ''
                            )
                        )
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row>
                    <v-col>
                      <v-checkbox
                        v-model="nominated_director_ids"
                        :error="$v.nominated_director_ids.$error"
                        multiple
                        :label="$t('dialogs.crPDFsGenerator.buttons.nominate')"
                        :value="director.id"
                        @change="() => {
                          if (nominated_director_ids.includes(director.id) && suspended_director_ids.includes(director.id)) {
                            suspended_director_ids.splice(suspended_director_ids.indexOf(director.id), 1);
                          }
                        }"
                      />
                    </v-col>
                    <v-col>
                      <v-checkbox
                        v-model="suspended_director_ids"
                        :error="$v.suspended_director_ids.$error"
                        multiple
                        :label="$t('dialogs.crPDFsGenerator.buttons.suspend')"
                        :value="director.id"
                        @change="() => {
                          if (suspended_director_ids.includes(director.id) && nominated_director_ids.includes(director.id)) {
                            nominated_director_ids.splice(nominated_director_ids.indexOf(director.id), 1);
                          }
                        }"
                      />
                    </v-col>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                :key="key"
                inset
              />
            </template>

            <div
              v-if="availableDirectors.length === 0"
              class="text-center"
            >
              {{ $t('dialogs.crPDFsGenerator.noRecords') }}
            </div>
          </v-list>
          <v-list
            subheader
            v-if="(forms.includes('ND2A') || forms.includes('NN6')) ||
              (forms.includes('ND2B') || forms.includes('NN7'))"
          >
            <v-subheader>{{ $t('dialogs.crPDFsGenerator.secretary.label') }}</v-subheader>

            <template v-for="(secretary, key) in availableSecretaries">
              <v-list-item :key="`secretary_${key}`">
                <v-list-item-avatar>
                  <v-icon
                    dark
                    class="grey lighten-1"
                  >
                    {{ secretary.person ? 'mdi-account-outline' : 'mdi-domain' }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ [(secretary.person || secretary.company).names[0].sur, (secretary.person || secretary.company).names[0].other].filter(Boolean).join(' ') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon small>
                      mdi-briefcase
                    </v-icon>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-row>
                    <v-col>
                      <v-checkbox
                        v-model="nominated_secretary_ids"
                        :error="$v.nominated_secretary_ids.$error"
                        multiple
                        :label="$t('dialogs.crPDFsGenerator.buttons.nominate')"
                        :value="secretary.id"
                        @change="() => {
                          if (nominated_secretary_ids.includes(secretary.id) && suspended_secretary_ids.includes(secretary.id)) {
                            suspended_secretary_ids.splice(suspended_secretary_ids.indexOf(secretary.id), 1);
                          }
                        }"
                      />
                    </v-col>
                    <v-col>
                      <v-checkbox
                        v-model="suspended_secretary_ids"
                        :error="$v.suspended_secretary_ids.$error"
                        multiple
                        :label="$t('dialogs.crPDFsGenerator.buttons.suspend')"
                        :value="secretary.id"
                        @change="() => {
                          if (suspended_secretary_ids.includes(secretary.id) && nominated_secretary_ids.includes(secretary.id)) {
                            nominated_secretary_ids.splice(nominated_secretary_ids.indexOf(secretary.id), 1);
                          }
                        }"
                      />
                    </v-col>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                :key="key"
                inset
              />
            </template>

            <div
              v-if="availableSecretaries.length === 0"
              class="text-center"
            >
              {{ $t('dialogs.crPDFsGenerator.noRecords') }}
            </div>
          </v-list>
          <v-list
            subheader
            v-if="forms.includes('NN8') || forms.includes('NN8C')"
          >
            <!-- <v-subheader>Auth Rep</v-subheader> -->

            <v-list-item>
              <v-select
                v-model="auth_rep_ids"
                :label="$t('dialogs.crPDFsGenerator.authRep.label')"
                :error="$v.auth_rep_ids.$error"
                :error-messages="!$v.auth_rep_ids.required ? $t('validate.required', { field: $t('dialogs.crPDFsGenerator.authRep.label') }) : null"
                multiple
                clearable
                :items="availableAuthReps"
                :item-value="item => item.id"
              >
                <template v-slot:item="{ item }">
                  <div>
                    {{ [(item.person || item.company).names[0].sur, (item.person || item.company).names[0].other].filter(Boolean).join(' ') }}
                  </div>
                </template>
                <template v-slot:selection="{ item }">
                  <v-chip>
                    {{ [(item.person || item.company).names[0].sur, (item.person || item.company).names[0].other].filter(Boolean).join(' ') }}
                  </v-chip>
                </template>
              </v-select>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            color="primary"
            :loading="loading"
            @click="onDownload"
          >
            <v-icon left>
              mdi-download
            </v-icon>
            {{ $t('dialogs.crPDFsGenerator.buttons.download') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { RBAClient } from 'rba-client'
const client = RBAClient.getInstance(require('@/api/config'))

Vue.use(Vuelidate)

export default {
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  watch: {
    catalog () {
      this.setCreationPDF()
    },
    company: {
      deep: true,
      handler (newCompany) {
        if (newCompany.company_type === 6) {
          // Non-HK company
          this.forms.splice(this.forms.indexOf('ND2A'), 1)
          this.forms.splice(this.forms.indexOf('ND5'), 1)
          // update info
          this.forms.splice(this.forms.indexOf('ND2B'), 1)
          this.forms.splice(this.forms.indexOf('ND7'), 1)
        } else {
          // HK company
          this.forms.splice(this.forms.indexOf('NN6'), 1)
          this.forms.splice(this.forms.indexOf('NN8'), 1)
          // update info
          this.forms.splice(this.forms.indexOf('NN7'), 1)
          this.forms.splice(this.forms.indexOf('NN8C'), 1)
        }
        this.setCreationPDF()
      }
    }
  },
  computed: {
    downloadUrl () {
      return client.cr_form.getCrFormDownloadUrl(this.forms, this.company.id, this.nominated_director_ids, this.suspended_director_ids, this.nominated_secretary_ids, this.suspended_secretary_ids, this.auth_rep_ids)
    },
    availableDirectors () {
      return this.company
        ? this.company.directors.filter(director => {
          // show Reserve Director while "ND5", "ND7" are selected
          return ((
            // changing role
            this.forms.includes('ND5') ||
            // updating information
            this.forms.includes('ND7')
          ) && director.director_type === 3) ||
          // show Director while "ND2A", "NN6", "ND2B", "NN7" are selected
          ((
            // changing role
            (this.forms.includes('ND2A') || this.forms.includes('NN6')) ||
            // updating information
            (this.forms.includes('ND2B') || this.forms.includes('NN7'))
          ) && director.director_type !== 3)
        })
        : []
    },
    availableSecretaries () {
      return this.company
        ? this.company.secretaries
        : []
    },
    availableAuthReps () {
      return this.company
        ? this.company.auth_reps
        : []
    }
  },
  data () {
    return {
      show: false,
      loading: false,
      catalog: '',
      forms: [],
      // director_ids: [],
      nominated_director_ids: [],
      suspended_director_ids: [],
      // secretary_ids: [],
      nominated_secretary_ids: [],
      suspended_secretary_ids: [],
      auth_rep_ids: []
    }
  },
  validations () {
    return {
      catalog: {
        required
      },
      forms: {
        required
      },
      nominated_director_ids: {
        required: requiredIf(() => {
          return (
            (this.forms.includes('ND5') || this.forms.includes('ND2A') || this.forms.includes('NN6')) ||
            (this.forms.includes('ND7') || this.forms.includes('ND2B') || this.forms.includes('NN7'))
          ) &&
          this.suspended_director_ids.length === 0 &&
          this.nominated_secretary_ids.length === 0 &&
          this.suspended_secretary_ids.length === 0
        })
      },
      suspended_director_ids: {
        required: requiredIf(() => {
          return (
            (this.forms.includes('ND5') || this.forms.includes('ND2A') || this.forms.includes('NN6')) ||
            (this.forms.includes('ND7') || this.forms.includes('ND2B') || this.forms.includes('NN7'))
          ) &&
          this.nominated_director_ids.length === 0 &&
          this.nominated_secretary_ids.length === 0 &&
          this.suspended_secretary_ids.length === 0
        })
      },
      nominated_secretary_ids: {
        required: requiredIf(() => {
          return (
            (this.forms.includes('ND2A') || this.forms.includes('NN6')) ||
            (this.forms.includes('ND2B') || this.forms.includes('NN7'))
          ) &&
          this.suspended_secretary_ids.length === 0 &&
          this.nominated_director_ids.length === 0 &&
          this.suspended_director_ids.length === 0
        })
      },
      suspended_secretary_ids: {
        required: requiredIf(() => {
          return (
            (this.forms.includes('ND2A') || this.forms.includes('NN6')) ||
            (this.forms.includes('ND2B') || this.forms.includes('NN7'))
          ) &&
          this.nominated_secretary_ids.length === 0 &&
          this.nominated_director_ids.length === 0 &&
          this.suspended_director_ids.length === 0
        })
      },
      auth_rep_ids: {
        required: requiredIf(() => {
          return this.forms.includes('NN8') || this.forms.includes('NN8C')
        })
      }
    }
  },
  methods: {
    onDownload () {
      this.$v.$touch()
      if (this.$v.$anyError) return
      if (this.loading) return
      this.loading = true
      // ensure access_token is valid
      this.$store.dispatch('setting/fetchLatestApplicationVersion').then(() => {
        window.open(this.downloadUrl, '_blank')
      }).finally(() => {
        this.loading = false
      })
    },
    setCreationPDF () {
      if (this.catalog === 'create') {
        this.forms.splice(0)
        switch (this.company.company_type) {
          case 1:
          case 2:
            this.forms.push('NNC1')
            this.forms.push('IRBR1') // display purpose
            break
          case 3:
          case 4:
          case 5:
            this.forms.push('NNC1G')
            this.forms.push('IRBR1') // display purpose
            break
          case 6:
            this.forms.push('NN1')
            this.forms.push('IRBR2') // display purpose
            break
        }
      } else {
        // Clear forms
        this.forms.splice(this.forms.indexOf('NNC1'), 1)
        this.forms.splice(this.forms.indexOf('NNC1G'), 1)
        this.forms.splice(this.forms.indexOf('NN1'), 1)
        this.forms.splice(this.forms.indexOf('IRBR1'), 1)
        this.forms.splice(this.forms.indexOf('IRBR2'), 1)
      }
    }
  }
}
</script>
