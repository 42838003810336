var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',[_c('v-app-bar',{staticClass:"white pa-0",attrs:{"flat":"","elevate-on-scroll":"","dense":""}},[_c('v-toolbar-title',{attrs:{"data-cy":"create_company_title"}},[_c('router-link',{staticClass:"text-decoration-none",staticStyle:{"color":"inherit"},attrs:{"to":{ name: 'Companies' }}},[_vm._v(" "+_vm._s(_vm.$t('company.listing.title'))+" ")]),(_vm.company)?_c('span',{staticClass:"font-weight-medium grey--text text-body-1"},[_c('v-icon',[_vm._v("mdi-chevron-right")]),_c('strong',[_vm._v(_vm._s(_vm._f("company_name")(_vm.company,1)))]),_vm._v("  "),_c('small',[_vm._v(_vm._s(_vm._f("company_name")(_vm.company,2)))])],1):_vm._e()],1),_c('v-spacer'),(_vm.company)?_c('PopupGenerateCrForm',{attrs:{"company":_vm.company},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var loading = ref.loading;
return [(_vm.company_id)?_c('v-btn',_vm._g({attrs:{"text":"","loading":loading}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-printer ")]),_vm._v(" "+_vm._s(_vm.$t('company.details.buttons.print'))+" ")],1):_vm._e()]}}],null,false,1807281467)}):_vm._e(),(_vm.company_id)?_c('v-btn',{attrs:{"text":"","data-cy":"save_company_btn"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.company.onComplete()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" "+_vm._s(_vm.$t('company.details.buttons.save'))+" ")],1):_vm._e(),(_vm.company_id)?_c('PopupDelete',{attrs:{"data-cy":"company"},on:{"yes":function (done) {
            _vm.$store.dispatch('company/remove', _vm.company_id)
              .then(function () {
                this$1.$router.replace({ name: 'Companies' })
              })
              .catch(function (error) { _vm.console.warn(error) })
              .finally(done); // notify popup stop loading and close
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var on = ref.on;
          var loading = ref.loading;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":"error","loading":loading,"data-cy":"delete_company_btn"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-delete ")]),_vm._v(" "+_vm._s(_vm.$t('company.details.buttons.delete'))+" ")],1)]}}],null,false,519272450)}):_vm._e()],1)],1),_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('loading',{attrs:{"active":_vm.requestLoading,"is-full-page":false},on:{"update:active":function($event){_vm.requestLoading=$event}}}),_c('Company2',{ref:"company",attrs:{"company-id":_vm.company_id},on:{"onLoad":function (company) {
          this$1.company = company;
        },"switchToID":_vm.onSwitchToID,"onSave":_vm.onSave}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }