<template>
  <v-card width="100%">
    <!-- <v-card-title>Company Info</v-card-title> -->
    <v-card-title>
      <v-app-bar
        flat
        class="white pa-0"
        elevate-on-scroll
        dense
      >
        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

        <v-toolbar-title
          data-cy="create_company_title"
        >
          <router-link
            :to="{ name: 'Companies' }"
            class="text-decoration-none"
            style="color: inherit"
          >
            {{ $t('company.listing.title') }}
          </router-link>
          <span
            v-if="company"
            class="font-weight-medium grey--text text-body-1"
          >
            <v-icon>mdi-chevron-right</v-icon>
            <strong>{{ company | company_name(1) }}</strong>&nbsp;
            <small>{{ company | company_name(2) }}</small>
          </span>
        </v-toolbar-title>

        <v-spacer />
        <!-- <v-btn
          text
          @click.prevent="print"
          v-if="company_id"
          data-cy="print_company_btn"
        >
          <v-icon left>
            mdi-printer
          </v-icon>
          {{ $t('company.details.buttons.print') }}
        </v-btn> -->
        <PopupGenerateCrForm
          v-if="company"
          :company="company"
          v-slot:default="{ on, loading }"
        >
          <v-btn
            text
            v-if="company_id"
            v-on="on"
            :loading="loading"
          >
            <v-icon left>
              mdi-printer
            </v-icon>
            {{ $t('company.details.buttons.print') }}
          </v-btn>
        </PopupGenerateCrForm>
        <!-- <v-btn
          text
          @click.prevent="dialog_print = true"
          v-if="company_id"
        >
          <v-icon left>
            mdi-printer
          </v-icon>
          {{ $t('company.details.buttons.print') }}
        </v-btn> -->
        <v-btn
          text
          v-if="company_id"
          @click.prevent="$refs.company.onComplete()"
          data-cy="save_company_btn"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>
          {{ $t('company.details.buttons.save') }}
        </v-btn>
        <PopupDelete
          v-if="company_id"
          v-slot:default="{ on, loading }"
          @yes="(done) => {
            $store.dispatch('company/remove', company_id)
              .then(() => {
                this.$router.replace({ name: 'Companies' })
              })
              .catch((error) => { console.warn(error) })
              .finally(done); // notify popup stop loading and close
          }"
          data-cy="company"
        >
          <v-btn
            text
            color="error"
            v-on="on"
            :loading="loading"
            data-cy="delete_company_btn"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
            {{ $t('company.details.buttons.delete') }}
          </v-btn>
        </PopupDelete>
        <!-- <v-dialog
          v-if="company_id"
          v-model="dialog_print"
        >
          <DialogPrint />
        </v-dialog> -->
      </v-app-bar>
    </v-card-title>
    <v-container
      fill-height
      fluid
    >
      <!-- <div style="display: flex;flex-direction: column;height: 100%;"> -->

      <!-- <section class="content"> -->
      <loading
        :active.sync="requestLoading"
        :is-full-page="false"
      />
      <!-- <div class="row">
				<div class="col-xs-12">
					<div class="box box-primary"> -->
      <!-- <v-form role="form" @submit.prevent="onSave" class="col-12"> -->
      <!-- <div class="box-body"> -->
      <Company2
        ref="company"
        :company-id="company_id"
        @onLoad="(company) => {
          this.company = company;
        }"
        @switchToID="onSwitchToID"
        @onSave="onSave"
      />
      <!-- </div> -->
      <!-- </v-form> -->
      <!-- </div>
				</div>
			</div> -->
      <!-- </section> -->
      <!-- <PopupPrint></PopupPrint> -->
      <!-- </div> -->
    </v-container>
  </v-card>
</template>

<script>

import * as helper from '@/store/helper'
// import PopupPrint from '@/components/popups/PopupPrint'
// import DialogPrint from '@/components/forms/PrintCR'
import Company2 from '@/components/forms/Company2'
import PopupDelete from '@/components/popups/PopupDeleteConfirmation'
import PopupGenerateCrForm from '@/components/popups/PopupGenerateCrForm'

export default {
  components: {
    Company2,
    // DialogPrint,
    PopupDelete,
    PopupGenerateCrForm
  },
  watch: {
    $route (to, unusedFrom) {
      if (to.name === 'CompanyEdit') {
        this.company_id = to.params.customer_id
      } else if (to.name === 'CompanyCreate') {
        this.company_id = 0
      }
    }
  },
  data () {
    return {
      requestLoading: false,
      company_id: 0,
      members: [],
      // form2: helper.initialCompany(),
      // dialog_print: false,
      company: null
    }
  },
  methods: {
    ...helper,
    onSave (saved_data) {
      if (!this.company_id) {
        return this.$router.replace({
          name: 'CompanyEdit',
          params: {
            customer_id: saved_data.id
          }
        })
      }
    },
    // print () {
    //   var vm = this

    //   this.$modal.show({
    //     render (h) {
    //       return h(PopupPrint, {
    //         props: {
    //           company: vm.$refs.company.form
    //           // requestLoading: requestLoading
    //         },
    //         on: {
    //           // onLoading: () => {
    //           // 	vm.requestLoading = true;
    //           // },
    //           // onLoaded: () => {
    //           // 	vm.requestLoading = false;
    //           // },
    //           onPrint: () => {

    //           }
    //         }
    //       })
    //     }
    //   },
    //   {
    //     // company: this.form2
    //   }, {
    //     height: 'auto',
    //     width: '600',
    //     scrollable: true
    //   })
    // },
    onSwitchToID (company_id) {
      // console.log(arguments);
      // this.company_id = company_id;
      this.$router.push({
        name: 'CompanyEdit',
        params: {
          customer_id: company_id
        }
      })
    }
  },
  created () {
    this.company_id = this.$route.params.customer_id
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
button.btn-sm.btn-block {
	width: 65px;
	float: left;
	margin: 0 5px;
}
.v--modal-overlay {
	z-index: 9999;
}
.box.box-primary {
	max-height: calc(100vh - 51px - 149px);
    overflow-y: auto;
    overflow-x: hidden;
}
.btn-primary {
	margin: auto 3px;
}
.content {width: 100%;}
.vue-dialog .dialog-content {
	max-height: 90vh;
    overflow: auto;
    word-break: break-all;
}
@media (max-width: 767px) {
	.box.box-primary {
		max-height: calc(100vh - 51px - 149px - 50px);
	}
}
</style>
